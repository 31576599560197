import './bootstrap';
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React from 'react';
import { ThemeProvider } from '@mui/material';
import { getTheme } from './utils/theme';
import * as Sentry from '@sentry/react';
import 'react-day-picker/dist/style.css';
import '../css/app.css';
import { GlobalStyles } from '@mui/material';
import { Provider } from 'react-redux';
import { store } from './store';
import { initAxios } from './utils/api';

// Default print media
// only shows header and page content (hide sidebar and footer)
import '@/../css/print/Base.css';

// PROD includes uat + staging by default
if (import.meta.env.PROD) {
    const isProd = import.meta.env.VITE_APP_ENV === 'production';

    Sentry.init({
        dsn: 'https://59ff70a7bc5341289101a201db18f119@o355431.ingest.sentry.io/4504692065763328',

        environment: import.meta.env.VITE_APP_ENV,
        release: import.meta.env.VITE_SENTRY_RELEASE,

        // only send replays on errors to avoid costs
        replaysSessionSampleRate: 0,

        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,

        integrations: [
            new Sentry.Replay({
                // Additional SDK configuration goes in here, for example:
                maskAllText: false,
                maskAllInputs: false,
                blockAllMedia: false,
            }),
        ],
    });
}

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

const theme = getTheme();

// hoist global styles to avoid recalculating on every render
const globalStyles = <GlobalStyles styles={{
    // React day picker styling
    '.rdp': {
        // selected date range color
        '--rdp-accent-color': theme.palette.tertiary.main,
        // button hover color
        '--rdp-background-color': theme.palette.tertiary.tint,
    },
}} />;

// Initialize interceptors for global error handling
initAxios(store);

createInertiaApp({
    title: (title) => title ? `${title} | ${appName}` : appName,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.tsx`, import.meta.glob('./Pages/**/*.tsx')),
    setup({ el, App, props }) {
        const root = createRoot(el);

        // Wrap the root App node in custom theme
        // https://mui.com/material-ui/customization/theming/
        root.render(
            <>
                {globalStyles}
                <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Provider store={store}>
                            <App {...props} />
                        </Provider>
                    </LocalizationProvider>
                </ThemeProvider>
            </>,
        );
    },
    progress: {
        color: theme.palette.primary.dark,
    },
});
